import React from "react";


import Cube from '../assets/img/vision/Cube.png';
import Sphere from '../assets/img/vision/Sphere.png';


const AboutSection = () => {
  // const [flip, set] = useState(false)
  
  return (
    <div>
      <section className="w-full bg-white pt-7 md:pt-20">
        <div className="box-border flex flex-col items-center content-center px-8 mx-auto leading-6 text-black border-0 border-gray-300 border-solid md:flex-row max-w-7xl lg:px-16">
          <div className="box-border order-first w-full text-black border-solid md:w-1/2 md:pl-10 md:order-none">
            <h2 className="m-0 text-xl font-bold text-primary-500 leading-tight border-0 border-gray-300 md:w-96 lg:text-5xl md:text-4xl">
            Work Hard, Realizing ideas
            </h2>
            <p 
            className="pt-6 pb-8 m-0 leading-7 font-medium text-[#151439] text-opacity-40 border-0 border-gray-300 sm:pr-8 xl:pr-24 text-lg lg:text-xl">
            
            Our mission is to help startups and businesses transform their ideas into real and 
            impactful products. We, well-trained coders in Java, Python, GoLang, C++, PHP, Angular, NodeJS, ReactJS, React Native as well as iOS and Android application development, always work to offer you the best software solutions.
            </p>
          </div>


          <div className="box-border relative w-full max-w-md px-4 mt-5 mb-4 -ml-5 text-center bg-no-repeat bg-contain border-solid md:ml-0 md:mt-0 md:max-w-none lg:mb-0 md:w-1/2 xl:pl-10">
          <div className="-mr-24 sm:-mr-40">
      
            <img
            src={Sphere}
              className="p-2 pl-6 "
              data-aos='fade-down'
              data-aos-duration="2000"
                alt="3d sphere"
             
            />
          {/* </animated.div> */}

          </div>

          </div>

        </div>
        <div className="box-border flex flex-col items-center content-center px-8 mx-auto mt-2 leading-6 text-black border-0 border-gray-300 border-solid md:mt-20 xl:mt-0 md:flex-row max-w-7xl lg:px-16">

        <div className="box-border relative w-full max-w-md px-4 mt-10 mb-4 text-center bg-no-repeat bg-contain border-solid md:mt-0 md:max-w-none lg:mb-0 md:w-1/2">
          <div className="sm:pr-10 mb-0 pb-0 h-auto lg:pr-32 ">
            <img
            src={Cube}
              id="power"
              className="w-96 m-0 p-0 h-96"
              data-aos='fade-down'
            data-aos-duration="2000"
              alt=""
            />
           </div>

          </div>

          <div className="box-border order-first w-full text-black border-solid md:w-1/2 md:pl-10 md:order-none">
            <h2 className="m-0 text-xl font-bold text-primary-500 leading-tight border-0 border-gray-300 md:w-96 lg:text-5xl md:text-4xl">
            Our <br/>
culture
            </h2>
            <p className="pt-6 m-0 leading-7 font-medium text-[#151439] text-opacity-40 border-0 border-gray-300 sm:pr-8 xl:pr-24 text-lg lg:text-xl">
            At Designveloper, we believe that a successful product must help clients resolve their business problem, and furthermore, improve their business growth. Impactful products, satisfied customers, developmental businesses are our service roadmap.
                      </p>
          </div>

        </div>
      </section>
    </div>
  );
};

export default AboutSection;