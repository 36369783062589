/* eslint-disable import/first */

import * as React from 'react';


import {  solute } from '../data'
import HorizontalSlider from './ServiceBottom'
import RadioButtonsGroup from './ServiceRadio';

let { image, image2 } = solute

 const ServiceForm = () => {

  return (
    <>
      <section className='bg-primary-200 -z-30 pt-7 pb-12 h-auto'>
        <div className='h-full'>
      
          <div className='flex items-center justify-center h-full relative'>

          <div className='absolute top-0 right-0'>
            <img src={image} alt="" 
             className='w-96 ' 
            data-aos="flip-right"
            data-aos-duration="2000"

           />
          </div>
            <div className="absolute bottom-0 left-0">

              <img src={image2} alt="velion"
             data-aos="flip-left"
             data-aos-duration="1000"
               className='w-56 md:w-96 md:-ml-36 -ml-20' />
            </div>

          <div className="flex pl-5 pt-9  mt-4 font-primary flex-col mx-2 w-96  md:w-[800px] z-10  pr-7 justify-center h-auto">
            {/* let's talk about your Project */}
            <h1 className="text-2xl md:text-3xl font-semibold text-white text-center">
              Let's talk about your project
            </h1>
            
      <form className="flex  flex-col bg-transparent items-start justify-center h-auto">
        {/* your information  */}
        <div className="flex flex-col items-start justify-center h-auto">
          <label className="text-white text-sm font-medium mt-5">
            Your Information
          </label>
          </div>


      <div className="flex mx-2 flex-col w-full md:flex-row items-center justify-evenly h-auto">
       <div className='block w-full px-1'>
        <label 
        className="pt-6 pl-3 w-full font-normal text-base pb-1 md:text-lg flex items-start flex-col m-0 leading-7 text-white  sm:pr-8">
          First Name
        </label>

          <input
            type="text"
            className='w-full py-3  pl-4 border-2 border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-primary-200 focus:border-transparent'
            id="fname"
            name="fname"
            placeholder="Your full name"
          />
       </div>

        </div>
        <div className="flex flex-col md:flex-row items-center w-full justify-evenly h-auto">
       <div className='block  w-full mx-1'>
        <label
              className="pt-6 pl-3 w-full font-normal text-base pb-1 md:text-lg flex items-start flex-col m-0 leading-7 text-white  sm:pr-8">
          Email
        </label>
          <input
            type="email"
            id="email"
            className='w-80 py-3 pl-3 border-2 border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-primary-200 focus:border-transparent'
            name="email"
            placeholder="Your email.."
          />
         </div>
         <div className='block w-full mx-1'>
        <label 
        className="pt-6 pl-3 w-full font-normal text-base pb-1 md:text-lg flex items-start flex-col m-0 leading-7 text-white  sm:pr-8">
            Phone Number
        </label>
            <input
                id="phone"
                name="phone"
                type="tel"
            className='w-80 py-3  pl-3 border-2 border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-primary-200 focus:border-transparent'
                placeholder="Your phone number.."
            />
            </div>

        </div>
      
        <label 
              className="pt-6  pl-3 w-full font-normal text-lg flex items-start flex-col m-0 leading-7 text-white  sm:pr-8 pb-1"
              >
           Could you brief your idea?
        </label>

            <textarea
                id="subject"
                name="subject"
                placeholder="Short description about your idea"
                style={{height: '160px'}}
            className='w-full py-3  pl-3 rounded-xl border-2 border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary-200 focus:border-transparent'
            />

            {/* what's your budget for this project? */}  
            <label
              className="pt-6 pl-3 w-full font-normal text-lg flex items-start flex-col m-0 leading-7 text-white  sm:pr-8 pb-1"
              >
            What's your budget for this project?
        </label>
        {/* range slider */}
        <div className="flex w-full items-center">
          <HorizontalSlider />
          </div>

          <div className="flex w-full items-center justify-center">
            <RadioButtonsGroup />
            </div>
     



        <button type="submit" value="Submit"
        className="w-52 py-3 my-8 text-white hover:scale-105 bg-black mx-auto pl-3 border-2  rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-200 focus:border-transparent"
         >
            Submit
        </button>
        </form>
    </div>
        </div>
        </div>
      </section>


    </>
  )
}

export default ServiceForm