import React from 'react';

// import data
import { nav } from '../data';

const NavMobile = ({ navMobile }) => {
  return (
    <nav
      className={`${
        navMobile ? 'min-h-screen' : 'min-h-0'
      } w-full fixed top-0 left-0 right-0 bg-primary-400 -bottom-12 -z-10 lg:hidden overflow-hidden transition-all h-0`}
    >
      <ul className='w-full top-0 left-0 h-full flex flex-col justify-center items-center gap-y-8'>
        {nav.map((item, idx) => {
          return (
            <li
            className='flex w-44 group justify-between items-center'
             key={idx}>
              <a
              //  className='text-white text-body-md'
              className={`
              ${item.active ? 'text-primary-200' : 'text-white'}
              text-body-md group-hover:text-black transition`
              }
               href={item.href}>
                {item.name}
              </a>

              <svg fill="none" stroke="currentColor" strokeLinecap="round" stroke-linejoin="round" stroke-width="2"
    className="w-5 text-white h-5 group-hover:text-black hover:translate-x-2 duration-200 ease-in-out transition fill-current group-hover:scale-125" viewBox="0 0 24 24">
     <path d="M5 12h14M12 5l7 7-7 7"></path>
   </svg>

            </li>
          );
        })}
      </ul>
      {/* buttons */}
      <div className='-mt-40 flex justify-center gap-x-8'>
        <button className='btn btn-lg text-primary-200 bg-white rounded-full mt-3 '>
               {/* user icon  */}
               <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" 
          className="w-5 h-5 mr-1">
  <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
</svg>
           Get Started
           </button>
      </div>
    </nav>
  );
};

export default NavMobile;
