import React from 'react'

const TeamsData = [
    {
        id: 1,
        img: 'https://cdn.devdojo.com/images/june2021/avt-05.jpg',
        alt: 'Team 1',
        name: 'John Doe',
        position: 'CEO',
        social: [
            {
                id: 1,
                icon: 'fab fa-facebook-f',
                link: '#'
            },
            {
                id: 2,
                icon: 'fab fa-twitter',
                link: '#'
            },
        ],
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. quod.',
    },
    {
        id: 2,
        alt: 'Team 2',
        img: 'https://cdn.devdojo.com/images/june2021/avt-06.jpg',
        name: 'Nicole Fisher',
        position: 'CTO',
        social: [
            {
                id: 1,
                icon: 'fab fa-facebook-f',
                link: '#'
            },
            {
                id: 2,
                icon: 'fab fa-twitter',
                link: '#'
            },
        ],
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. quod.',
    },
    {
        id: 3,
        img: 'https://images.pexels.com/photos/2379005/pexels-photo-2379005.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
        name: 'Jane Doe',
        alt: 'Team 3',
        position: 'COO',
        social: [
            {
                id: 1,
                icon: 'fab fa-facebook-f',
                link: '#'
            },
            {
                id: 2,
                icon: 'fab fa-twitter',
                link: '#'
            },
        ],
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. quod.',
    },
    {
        id: 4,
        img: 'https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80',
        name: 'Johnny Dean',
        alt: 'Team 4',
        position: 'CMO',
        social: [
            {
                id: 1,
                icon: 'fab fa-facebook-f',
                link: '#'
            },
            {
                id: 2,
                icon: 'fab fa-twitter',
                link: '#'
            },
        ],
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. quod.',
    },
    {
        id: 5,
        img: 'https://images.pexels.com/photos/3812011/pexels-photo-3812011.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
        name: 'Nicole Fisher',
        alt: 'Team 5',
        position: 'Founder',
        social: [
            {
                id: 1,
                icon: 'fab fa-facebook-f',
                link: '#'
            },
            {
                id: 2,
                icon: 'fab fa-twitter',
                link: '#'
            },
        ],
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. quod.',
    },
    {
        id: 6,
        img: 'https://images.pexels.com/photos/1024311/pexels-photo-1024311.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
        name: 'Jane Doe',
        alt: 'Team 6',
        position: 'CEO',
        social: [
            {
                id: 1,
                icon: 'fab fa-facebook-f',
                link: '#'
            },
            {
                id: 2,
                icon: 'fab fa-twitter',
                link: '#'
            },
        ],
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. quod.',
    },
        
]

export const AboutTeams = () => {
  return (
    <section className="w-full py-16 bg-white lg:py-24">
    <div className="px-12 mx-auto text-center max-w-7xl">
      <div className="space-y-12 md:text-left">
        <div className='flex flex-col sm:flex-row justify-center'>
        <div className="mb-20 mt-5 space-y-1 sm:mx-auto  sm:max-w-xl md:max-w-2xl sm:space-y-2 lg:max-w-5xl">
          <h2 className="relative text-left text-primary-200 text-4xl font-extrabold tracking-tight sm:text-5xl">
        Our <br/> Team
          </h2>
        </div>
        <div className="mx-auto space-y-16 sm:grid sm:grid-cols-2 sm:gap-16 sm:space-y-0 lg:grid-cols-3 lg:max-w-5xl">
        {
            TeamsData.map((item) => {
            //     <div className="space-y-6 text-center lg:ml-0 md:ml-10 ">
            //     <div className="relative w-48 h-48 p-2 mx-auto md:w-56 md:h-56">
            //       <img
            //         className="relative z-20 w-full h-full rounded-xl"
            //         src="https://cdn.devdojo.com/images/june2021/avt-05.jpg"
            //         alt=""
            //       />
                 
            //     </div>
            //     <div className="space-y-2">
            //       <div className="space-y-1 text-lg font-medium leading-6">
            //         <h3>Mike Jones</h3>
            //         <p className="text-blue-500">Founder &amp; CEO</p>
            //         {/* description */}
            //         <p className="text-gray-500">
            //         Designer who makes easy to change and easy to create new elements.
            //             </p>
    
    
            //       </div>
            //       <div className="relative mt-2 flex items-center justify-center space-x-3">
            //                 <a href="#_" className="text-gray-300 hover:text-gray-400">
            //                     <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 fill-current" viewBox="0 0 24 24"><path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"></path></svg>
            //                 </a>
            //                 <a href="#_" className="text-gray-300 hover:text-gray-400">
            //                     <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 fill-current" viewBox="0 0 24 24"><path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z"></path></svg>
            //                 </a>
            //       </div>
            //     </div>
            //   </div>
            return (


              <div className="space-y-6 text-center lg:ml-0 md:ml-10 ">
                <div className="relative w-48 h-48 p-2 mx-auto md:w-56 md:h-56">
                    <img
                    className="relative z-20 w-full h-full rounded-xl"
                    src={item.img}
                    alt={item.alt}
                    />
                </div>
                <div className="space-y-2">
                    <div className="space-y-1 text-lg font-medium leading-6">
                    <h3 className=' font-semibold text-2xl'>{item.name}</h3>
                    <p className="text-blue-500">{item.position}</p>
                    {/* description */}
                    <p className="text-gray-500">
                    {item.description}
                    </p>
                    </div>
                    <div className="relative mt-2 flex items-center justify-center space-x-3">
                    <a href="#_" className="text-gray-300 hover:text-gray-400">
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 fill-current" viewBox="0 0 24 24"><path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"></path></svg>
                    </a>
                    <a href="#_" className="text-gray-300 hover:text-gray-400">
                                 <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 fill-current" viewBox="0 0 24 24"><path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z"></path></svg>
            </a>
                    </div>
                </div>
                </div>
 

            )        
            })}
         

          
        
        </div>
        </div>
      </div>
    </div>
  </section>
  )
}
