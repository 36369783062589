import * as React from 'react';

export default function ProductForm() {
  return (
    <div className="flex pl-5 font-primary flex-col w-full md:w-fit md:max-w-xl bg-[#3D5AA9]/10  pr-7 justify-center h-auto">
      <h3 
      className="text-3xl font-primary font-bold leading-relaxed text-primary-200 text-start pr-6 py-6"
      >
        Request a quote
      </h3>
      <form className="flex  flex-col items-start justify-center h-auto">
      <div className="flex mx-2 flex-col w-full md:flex-row items-center justify-evenly h-auto">
       <div className='block w-full px-1'>
        <label 
        className="pt-6  pl-3 w-full font-medium text-lg flex items-start flex-col m-0 leading-7 text-[#425466]  sm:pr-8 pb-3"
        
        >
          First Name
        </label>

          <input
            type="text"
            className='w-full py-3  pl-3 border-2 border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-primary-200 focus:border-transparent'
            id="fname"
            name="fname"
            placeholder="Enter your first name"
          />
       </div>
      
      <div 
      className='block  w-full px-1'>

        <label 
        className="pt-6  pl-3 w-full font-medium text-lg flex items-start flex-col m-0 leading-7 text-[#425466]  sm:pr-8 pb-3"
        >
          Last Name
        </label>
          <input
            type="text"
            id="lname"
            name="lname"
            className='w-full py-3  pl-3 border-2 border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-primary-200 focus:border-transparent'
            placeholder="Your last name.."
          />
      </div>

        </div>
        <div className="flex flex-col md:flex-row items-center w-full justify-evenly h-auto">
       <div className='block  w-full mx-1'>
        <label
        className="pt-6 pl-3 w-full font-medium text-lg flex items-start flex-col m-0 leading-7 text-[#425466]  sm:pr-8 pb-3"
         >
          Work Email
        </label>

          <input
            type="text"
            id="email"
            className='w-full py-3  pl-3 border-2 border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-primary-200 focus:border-transparent'
            name="email"
            placeholder="Your email.."
          />
         </div>
         <div className='block w-full mx-1'>
        <label 
        className="pt-6  pl-3 w-full font-medium text-lg flex items-start flex-col m-0 leading-7 text-[#425466]  sm:pr-8 pb-3"
        >
            Phone Number
        </label>

            <input
                type="text"
                id="phone"
                name="phone"
            className='w-full py-3  pl-3 border-2 border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-primary-200 focus:border-transparent'
                placeholder="Your phone number.."
            />
            </div>

        </div>
        <label 
        className="pt-6 pl-3 w-full font-medium text-lg flex items-start flex-col m-0 leading-7 text-[#425466]  sm:pr-8 pb-3"
        >
            Company Name
        </label>

            <input

                type="text"
                id="company"
                name="company"
                placeholder="Your company name.."
            className='w-full py-3  pl-3 border-2 border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-primary-200 focus:border-transparent'
            />

<div class="block w-full">
                <label for="country" class=" pt-6 pl-3 w-full font-medium text-lg flex items-start flex-col m-0 leading-7 text-[#425466]  sm:pr-8 pb-3 ">Country</label>
                <select id="country" name="country" autocomplete="country-name" 
                class="mt-1 block w-full rounded-full border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                  <option>United States</option>
                  <option>Canada</option>
                  <option>Mexico</option>
                  <option>Andrey</option>
                </select>
              </div>
      
        <label 
              className="pt-6  pl-3 w-full font-medium text-lg flex items-start flex-col m-0 leading-7 text-[#425466]  sm:pr-8 pb-3"
              >
            Project Description
        </label>

            <textarea
                id="subject"
                name="subject"
                placeholder="Write something.."
                style={{height: '200px'}}
            className='w-full py-3  pl-3 rounded-md border-2 border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary-200 focus:border-transparent'
            />

        <button type="submit" value="Submit"
        className="w-full py-3 my-10 text-white bg-primary-200 pl-3 border-2 border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-primary-200 focus:border-transparent"
         >
            Submit
        </button>
        </form>
    </div>

  );
}



