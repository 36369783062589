// data-aos='fade-up'
// data-aos-delay='200'
import Logo from '../assets/img/footer/logow.svg';
import React from 'react'


const Footer = () => {
return (

<footer className="bg-darker-100 -mt-4 sm:-mt-0">

  <div
  className="mx-auto w-fit px-3 max-w-s pt-16 pb-6">
    <div className="lg:flex lg:items-center justify-center">
    <div className="  ">
    <div className="text-center w-full mx-auto py-12 lg:py-16 z-20">
        <h2 className=" font-extrabold text-ligther-100">
            <span className="block  lg:px-8 px-2 sm:px-6 text-6xl sm:text-7xl md:text-8xl">
               Got an Idea?
            </span>
            <span className="block lg:px-8 text-base sm:text-lg font-normal px-4 sm:px-6 w-full mt-4 pt-2 text-ligther-100">

            Slite helps remote teams share ideas, save knowledge, and work together anytime, any place.
            
            </span>
        </h2>
        <div className="lg:mt-0 lg:flex-shrink-0">
            <div className="mt-12 inline-flex rounded-md shadow">
            <button className='btn rounded-full btn-sm btn-primary'>
          {/* user icon  */}
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" 
          className="w-5 h-5 mr-2">
  <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
</svg>


          Get In Touch
          </button>
            </div>
        </div>
    </div>
</div>
</div>


    <div
      className="mt-14 grid grid-cols-1 gap-8 pt-16 md:grid-cols-3 lg:grid-cols-6"
    >
     <div className="text-center sm:text-left md:col-span-4 lg:col-span-2">
     <div
        className="flex justify-start sm:justify-start"
      >
        <img src={Logo} alt='' className='w-40 ' />
      </div>


        <div className=" text-left  mt-8 max-w-md sm:ml-0">
          <p
            className="text-left uppercase leading-relaxed text-ligther-100 font-semibold text-xs sm:text-left"
          >
            Contact
          </p>

          <h1 className="text-left text-ligther-100 font-bold text-3xl md:font-extrabold md:text-4xl sm:text-left">
            email@gmail.com
          </h1>
        </div>
      </div>

    
      <div className="text-center sm:text-left">
        <p className="text-lg  font-semibold text-primary-400">
        Resources
        </p>

        <nav className="mt-8">
          <ul className="space-y-4 text-sm">
            <li>
              <a
                className="text-ligther-100 transition hover:text-ligther-100/75"
                href="/"
              >
              Blogs
              </a>
            </li>

            <li>
              <a
                className="text-ligther-100 transition hover:text-ligther-100/75"
                href="/"
              >
               News updates
              </a>
            </li>

            <li>
              <a
                className="group flex justify-center gap-1.5 sm:justify-start"
                href="/"
              >
                <span
                  className="text-ligther-100 transition group-hover:text-ligther-100/75"
                >
                  Live Chat
                </span>

                <span className="relative -mr-2 flex h-2 w-2">
                  <span
                    className="absolute inline-flex h-full w-full animate-ping rounded-full bg-teal-400 opacity-75"
                  ></span>
                  <span
                    className="relative inline-flex h-2 w-2 rounded-full bg-teal-500"
                  ></span>
                </span>
              </a>
            </li>
          </ul>
        </nav>
      </div>

      <div className="text-center sm:text-left">
        <p className="text-lg  font-semibold text-primary-400">
         Navigation
        </p>

        <nav className="mt-8">
          <ul className="space-y-4 text-sm">
            <li>
              <a
                className="text-ligther-100 transition hover:text-ligther-100/75"
                href="/"
              >
                                             About
              </a>
            </li>

            <li>
              <a
                className="text-ligther-100 transition hover:text-ligther-100/75"
                href="/"
              >
                Course
              </a>
            </li>

            <li>
              <a
                className="text-ligther-100 transition hover:text-ligther-100/75"
                href="/"
              >
                Teachers
              </a>
            </li>

            <li>
              <a
                className="text-ligther-100 transition hover:text-ligther-100/75"
                href="/"
              >
                Pricing
              </a>
            </li>
          </ul>
        </nav>
      </div>

     

      <div className="text-center sm:text-left md:col-span-4 lg:col-span-2">
        <p className="text-lg font-semibold text-primary-400">
          Stay in Touch
        </p>

        <div className="mx-auto mt-8 max-w-md sm:ml-0">
          <p
            className="text-center leading-relaxed text-ligther-100 sm:text-left"
          >
           Stay Informed On How You Can Make a Difference
          </p>

          <form className="mt-4">
            <div
              className="flex flex-col gap-4 sm:flex-row lg:flex-col lg:items-start"
            >
              <label for="email" className="sr-only">Email</label>

              <input
                className="w-full rounded-full placeholder-[#7A8398] bg-[#E0EDFF] border-gray-100 px-6 py-3 shadow-sm"
                type="email"
                placeholder="Your email address"
              />

              <button
                className="block rounded-full bg-[#3D5AA9] px-8 py-3 font-medium text-white transition hover:bg-indigo-500"
                type="submit"
              >
                Subscribe
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div
      className="mt-16 border-t border-gray-100 pt-6 sm:flex sm:flex-col sm:items-center sm:justify-center"
    >
      <p
        className="text-center mb-4 text-sm text-ligther-100 "
      >
       © 2023 All Rights Reserved -  Velio

      </p>
      {/* created by Andrey Scott  */}
      <p>
        <a
          className="text-center text-xs text-ligther-100 "
          href="https://www.linkedin.com/in/andrew-irorere"
        >
          created by Andrey Scott
        </a>

      </p>

    </div>
  </div>
</footer>
)
}
export default Footer