import React from 'react'
import image from '../assets/img/service/image.svg';
import top from '../assets/img/service/top.svg';
import bottom from '../assets/img/service/bottom.svg';


export const ServiceChoose = () => {
  return (
    <section className="relative w-full h-auto py-8 overflow-hidden bg-white sm:py-12 md:py-20 lg:py-32">
  <img 
  src="https://cdn.devdojo.com/images/march2021/bg-gradient.png" 
  loading='lazy'
  alt='velion'
  className="absolute left-0 object-cover w-full h-full opacity-50 sm:opacity-100"/>
  <div className="absolute inset-0 w-full h-full bg-gradient-to-r from-transparent to-white"></div>
  <div className="relative flex flex-col items-center justify-start h-full mx-auto px-14 max-w-7xl lg:flex-row">

      <div className="relative z-10 w-full h-full lg:w-1/2 xl:pr-12 2xl:pr-24">
          <div className="flex flex-col items-start justify-center h-full pt-12 lg:pt-0">
              <h1 className=" w-full  mx-auto text-4xl font-bold tracking-tight text-left text-gray-700 lg:mx-0 sm:text-5xl lg:text-6xl lg:text-left">
                  <span className="text-transparent bg-clip-text bg-gradient-to-r from-primary-200 to-blue-500">Why choose
<br/> </span>
Velion?
              </h1>
              <p className="max-w-md mx-auto mt-4 text-left text-gray-500 lg:mx-0 lg:text-left">
                Allowing you to have more time back in your day to work on the things that matter and multiple high fidelity conversations all at the same time.</p>
              <div className="max-w-lg md:mx-auto lg:mx-0">
   
                  <div 
                    data-aos="fade-up"
                    data-aos-duration="3000"
                  className=" py-8 grid-cols-2 gap-8 grid">
                      <div className="col-span-1 text-center lg:text-left">
                          <h4 className="text-3xl font-bold tracking-tight text-transparent lg:text-4xl bg-clip-text bg-gradient-to-r from-blue-300 to-blue-500">99
                         <span className='text-red-500'>
                         %  </span> 
                         </h4>
                          <p className="text-sm font-semibold text-gray-400">MEETINGS</p>
                      </div>
                      <div className="col-span-1 text-center lg:text-left">
                          <h4 className="text-3xl font-bold tracking-tight text-transparent lg:text-4xl bg-clip-text bg-gradient-to-r from-blue-300 to-blue-500">205M
                          <span className='text-green-500'>
                         +  </span> 
                          </h4>
                          <p className="text-sm font-semibold text-gray-400">Happy users</p>
                      </div>
                      <div className="col-span-1 text-center lg:text-left">
                          <h4 className="text-3xl font-bold tracking-tight text-transparent lg:text-4xl bg-clip-text bg-gradient-to-r from-blue-300 to-blue-500">100K
                          <span className='text-yellow-500'>
                         +  </span> 
                          </h4>
                          <p className="text-sm font-semibold text-gray-400">PROJECTS DELIVERED</p>
                      </div>
                      <div className="col-span-1 text-center lg:text-left">
                          <h4 className="text-3xl font-bold tracking-tight text-transparent lg:text-4xl bg-clip-text bg-gradient-to-r from-blue-300 to-blue-500">200+</h4>
                          <p className="text-sm uppercase font-semibold text-gray-400">Premium modules</p>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      <div className="relative z-10 w-full h-full px-10 pb-32 mt-16 lg:w-1/2 md:px-20 lg:px-5 xl:px-0 lg:pb-0 lg:mt-0 group">

          <div
          data-aos="fade-up"
          data-aos-duration="2000"
           className="relative flex items-center justify-center w-full h-full">
              <div className="relative w-full h-auto md:h-full lg:h-auto">
                  <img 
                  className="absolute right-0 z-10 object-cover object-center w-48 md:w-52 lg:w-56 xl:w-72 -mt-8 -mr-8 transition duration-300 transform rounded-md shadow-2xl lg:top-0 lg:-mt-16 md:-mr-16 lg:-mr-6 md:rounded-lg lg:rounded-xl group-hover:scale-105" 
                  src={top}
                   alt="profile"
                   loading='lazy'
                   />
                  <img 
                  className="absolute bottom-16 h-auto left-0 z-10 object-cover object-center w-48 md:w-52 lg:w-56 xl:w-72 -mb-12 -ml-4 transition duration-300 transform rounded-md shadow-2xl  md:-ml-12 md:rounded-lg lg:rounded-xl group-hover:scale-95" 
                  src={bottom}
                  loading="lazy" 
                   alt="imageleft"/>
                  <div className="relative w-full h-auto overflow-hidden transition duration-300 transform  rounded-xl group-hover:scale-95">
                      <img className="w-full" 
                      src={image}
                      loading="lazy"
                      alt="image1"/>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>
  )
}
