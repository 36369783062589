import * as React from 'react';
// import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

function valuetext(value: number) {
  return `${value}°C`;
}

const marks = [
  {
    value: 0,
    label: '1000$',
  },

  {
    value: 100,
    label: '5000$',
  },
];

export default function HorizontalSlider() {
  return (
    <Box sx={{ width: 600 }}>
  
      <Slider
        getAriaLabel={() => 'Temperature'}
        getAriaValueText={valuetext}
        defaultValue={[20, 37]}
        valueLabelDisplay="auto"
        marks={marks}
      />
      </Box>

  );
}
