import React from 'react'
import CheckboxLabels from '../components/CheckedBox'
import ProductForm from '../components/ProductIdForm'

export const Productids = () => {
  return (
    <>
            <div className="box-border font-primary order-first w-full text-black pl-4 md:pl-10">
            <h1
             className=" text-2xl mt-20 font-bold text-primary-200 leading-tight border-0 border-gray-300 md:w-96 lg:text-3xl md:text-2xl">
            Product 1
            </h1>
            {/* software Development */}
            <div className="flex flex-col items-start justify-center h-auto">
      <h3 className="text-xl font-primary font-semibold md:font-bold leading-relaxed text-black text-start pt-6">
        Software Development
        </h3>

            <p className="pt-6 m-0 leading-7 md:w-3/5 font-normal text-[#151439]  sm:pr-8 pb-3 md:text-base  text-sm">
            At Designveloper, we believe that a successful product must help clients resolve their business problem, and furthermore, improve their business growth.
                      </p>
                      </div>
                      {/* checkbox section  */}
                      <div className="flex flex-col my-6 items-start justify-center h-auto">
                      <CheckboxLabels />
                      </div>

                      <ProductForm />
          </div>

    </>
  )
}
