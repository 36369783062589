import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

export default function RadioButtonsGroup() {
  return (
    <FormControl 
    className="w-full text-white text-sm font-normal font-primary "
    >
      <label
      id="demo-radio-buttons-group-label"
    //   text should be white 
        className="text-white text-sm mt-8 pb-3 font-normal font-primary "
      >
      What is your expected project timeline?
        </label>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="female"
        name="radio-buttons-group"
      >
        <FormControlLabel value="less than a month" control={<Radio />} label="less than a month" />
        <FormControlLabel value="3-6 months" control={<Radio />} label="1-2 months" />
        <FormControlLabel value="3-6 months" control={<Radio />} label="3-6 months" />
        <FormControlLabel value="other" control={<Radio />} label="6-12 months" />
      </RadioGroup>
    </FormControl>
  );
}
