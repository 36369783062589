import React from 'react'
import AboutBanner from '../components/AboutBanner'
import { AboutClient } from '../components/AboutClient'
import AboutSection from '../components/AboutSection'
import { AboutTeams } from '../components/AboutTeams'
import { AboutVision } from '../components/AboutVision'

export const AboutUs = () => {
  return (
    <div>
        <AboutBanner />
        <AboutVision />
        <AboutSection />
        <AboutClient />
        <AboutTeams />
    </div>
  )
}
