import React from 'react'

const GridCard = [
  {
    id: 1,
    title: 'General Question',
    text: 'Have a general question for us? Contact us at',
    email: 'contact@velion.com',
    arrow: <svg fill="none" stroke="currentColor" strokeLinecap="round" stroke-linejoin="round" stroke-width="2"
    className="xl:w-8 xl:h-8 group-hover:text-white" viewBox="0 0 24 24">
     <path d="M5 12h14M12 5l7 7-7 7"></path>
   </svg>
  },
  {
    id: 2,
    title: 'Business Development',
    text: 'Are you a reseller, affiliate, or association that would like to partner with Prunedge? Get connected with our Partner team at',
    email: 'partners@velion.com',
    arrow:  <svg fill="none" stroke="currentColor" strokeLinecap="round" stroke-linejoin="round" stroke-width="2"
    className="xl:w-8 xl:h-8 group-hover:text-white" viewBox="0 0 24 24">
     <path d="M5 12h14M12 5l7 7-7 7"></path>
   </svg>
  },
  {
    id: 3,
    title: 'Request For Quote',
    text: 'Want to do business with us Send a Request for Proposal and we will respond within five business days.',
    email: 'info@velion.com',
    arrow: <svg fill="none" stroke="currentColor" strokeLinecap="round" stroke-linejoin="round" stroke-width="2"
    className="xl:w-8 xl:h-8 group-hover:text-white" viewBox="0 0 24 24">
     <path d="M5 12h14M12 5l7 7-7 7"></path>
   </svg>
  },
  {
    id: 4,
    title: 'Job Seekers',
    text: 'Interested in Joining our super team of Pruendgers? View listing of available oppurtunities here',
    email: 'View Listing',
    arrow: <svg fill="none" stroke="currentColor" strokeLinecap="round" stroke-linejoin="round" stroke-width="2"
    className="xl:w-8 xl:h-8 group-hover:text-white" viewBox="0 0 24 24">
     <path d="M5 12h14M12 5l7 7-7 7"></path>
   </svg>
  }
]

export const ContactDettails = () => {
  return (
    <div>
      {/* create a contact details section */}
     <div className="flex flex-col items-center justify-center py-9 w-full h-full bg-[#fafafa]">
        <div className="flex flex-col md:flex-row items-start md:items-center justify-center w-full h-full">
          <div className="text-left  ml-10 sm:pl-4 md:ml-16 mt-8 md:w-2/5 text-darker-100 font-bold text-3xl md:text-4xl lg:text-5xl">
            <h2 className="text-left text-darker-100 pt-3 font-medium text-lg">
            Call us now </h2>
            {/* large phone number position left */}
            <a href="tel:+1-800-123-4567" 
            className="text-left mb-4 pb-6 font-bold text-3xl md:text-4xl">
            +1-800-123-4567 </a>

            <h6 className="text-left pt-4 mb-4 text-primary-200 font-bold text-3xl md:text-4xl ">
              Office Address
              </h6>
 
              <div className="flex flex-col mb-3 items-start justify-start w-full h-full">
                {/* location text position left */}
                <p 
                className="text-left pb-1 text-darker-100 font-medium text-sm md:text-base lg:text-lg">
                Lagos Office (HQ)
                </p>
                <p className="text-left text-darker-100 font-normal text-sm md:text-base lg:text-lg">
                32 Ade Ajayi St, Ogudu GRA 100242, Lagos
                </p>
                </div>

                <div className="flex flex-col mb-8 items-start justify-start w-full h-full">
                {/* location text position left */}
                <p 
                   className="text-left pb-1 text-darker-100 font-semibold text-sm md:text-base lg:text-lg">
               Abuja Office 
                </p>
                <p className="text-left text-darker-100 font-medium text-sm md:text-base lg:text-lg">
                32 Ade Ajayi St, Ogudu GRA 100242, Lagos
                </p>

</div>
</div>
   {/* a 2 by 2 divs in a grid placed center with little space in-between  */}
    <div className="grid mx-2 sm:mx-0 sm:ml-0 md:w-3/5  grid-cols-2 md:gap-2 md:grid-cols-2 lg:grid-cols-2 w-full place-items-center">
    
      {GridCard.map((item) => (
        <div key={item.id} className="flex flex-col items-center justify-center w-full h-full">
          <div className=" w-[150px] sm:w-52 xl:w-80 h-fit xl:rounded-md text-left bg-primary-400 text-white ">
            <h3 className="pl-3 pt-3 text-white pb-2 font-bold text-sm md:text-base lg:text-lg">
              {item.title}
            </h3>
            <p 
            className="text-white pb-1 xl:py-2 px-2 leading-6 xl:leading-9 font-medium text-xs md:text-sm xl:text-base ">
              {item.text}
            </p>
            <div className="flex flex-row items-center justify-between bg-dark px-1 md:px-3 xl:px-5">
            <p className="text-center text-white mdfont-medium text-xs md:text-sm xl:text-base underline ">
              {item.email}
          </p>
          <div className="flex hover:translate-x-1 duration-200 ease-in-out flex-row items-center justify-center w-8 h-8 xl:w-12 h-12 rounded-full">
            {item.arrow} 
            </div>
            </div>

            </div>
            </div>
            ))}


            
            </div>

            </div>
            </div>
            </div>
            
  )
}
