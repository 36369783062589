import React from 'react'
import { Chooseus } from './Chooseus'

import check from '../assets/img/service/check.svg';

let BoxDetails = [
    {
        id: 1,
        title: 'Consultation',
        text:'Free of Charge',
        text2:'Consult meeting',
        text3:'Basic specs definition and technical analytics',
        text4:'Rough estimation',
        text5:'Email supporting (reply as latest in 1 working day)',
        text6:'Online chatting room support',
    },
    {
        id: 2,
        title: 'Business',
        text: '500$ (will be refunded if sign contract and start)',
        text2:'Detailed and deep dive of consult meeting',
        text3:'Detailspec definition and technical analytic',
        text4:'Detailed estimation',
        text5:'Email support (reply as latest in 1 hour if in working time)',
        text6:'Online chatting room (reply right-way in working time)',
    },
]



export const ServiceBoxSection = () => {
  return (
    <div 
    data-aos="fade-up"

    className="mx-auto flex flex-col pt-12 items-center justify-center h-auto ">

 <Chooseus />

<div className="flex flex-col w-full  px-2 md:flex-row items-center justify-center h-auto">
 {
        BoxDetails.map((item) => {
            return (
                <div className="block h-full w-full mx-2 md:mx-0 mb-10 ">
                <h2 className='uppercase font-primary text-lg font-bold text-white  w-full py-3 text-center bg-primary-200 '>                
                 {item.title} 
                </h2>
                 <div className="flex flex-col items-start bg-[#3f3a3b]/10 justify-center h-auto">
                 <div className="flex items-center md:pl-16 pl-5 justify-start w-full h-auto">
                 <img src={check} alt="" className="w-4 h-4 mr-2" />
                 <p className="md:text-sm text-xs flex-wrap py-3 font-primary font-normal leading-9 text-black">
                 {item.text}
                 </p>
                 </div>
                 <div className="flex items-center md:pl-16 pl-5 justify-start w-full h-auto">
                 <img src={check} alt="" className="w-4 h-4 mr-2" />
                 <p className="md:text-sm text-xs flex-wrap py-3 font-primary font-normal leading-9 text-black">
                 {item.text2}
                 </p>
                 </div>
                 <div className="flex items-center md:pl-16 pl-5 justify-start w-full h-auto">
                 <img src={check} alt="" className="w-4 h-4 mr-2" />
                 <p className="md:text-sm text-xs flex-wrap py-3 font-primary font-normal leading-9 text-black">
                 {item.text3}
                 </p>
                 </div>
                 <div className="flex items-center md:pl-16 pl-5 justify-start w-full h-auto">
                 <img src={check} alt="" className="w-4 h-4 mr-2" />
                 <p className="md:text-sm text-xs flex-wrap py-3 font-primary font-normal leading-9 text-black">
                 {item.text4}
                 </p>
                 </div>
                 <div className="flex items-center md:pl-16 pl-5 justify-start w-full h-auto">
                 <img src={check} alt="" className="w-4 h-4 mr-2" />
                         <p className="md:text-sm text-xs flex-wrap py-3 font-primary font-normal leading-9 text-black">
                 {item.text5}
                 </p>
                 </div>
                 <div className="flex items-center md:pl-16 pl-5 justify-start w-full h-auto">
                 <img src={check} alt="" className="w-4 h-4 mr-2" />
                 
                                     <p className="md:text-sm text-xs flex-wrap py-3 font-primary font-normal leading-9 text-black">
                    {item.text6}
                    </p>
                    </div>
                    </div>
                    </div>
            )
        })


 }
 
    </div>
    
        
    </div>
  )
}
