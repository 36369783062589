import React from 'react'
import ContactBanner  from '../components/ContactBanner'
import { ContactDettails } from '../components/ContactDettails'

export const ContactUs = () => {
  return (
    <>
    <ContactBanner />

    <ContactDettails />

    </>
  )
}
