import React from 'react'

import scroll from '../assets/img/banner/scroll.png';
import Cube from '../assets/img/product/HollowCube.png';
import position from '../assets/img/product/position.svg';
import chart from '../assets/img/product/chart-bar.svg';
import flower from '../assets/img/product/flower.svg';
import flower2 from '../assets/img/product/flower-.svg';
import money from '../assets/img/product/money.svg';
import shield from '../assets/img/product/shield.svg';


export const ProductDetails = () => {
  return (
    <section>
        <div className="">
        <div className='hidden w-full sm:top-0 sm:left-0 sm:right-0 sm:flex justify-center items-center mt-6'>
            <div className='flex flex-col items-center w-full mx-auto '>
              <img 
              src={scroll}
              alt=''
              className='h-20 mb-10 animate-bounce ' 
              />
          
              </div>
              </div>
              <div className='flex flex-col lg:flex-row'>

                {/* div with a header a description and a cube image */}
                <div className='flex lg:w-1/2 px-2 flex-col pt-6 items-start md:items-start md:pl-10 md:pr-10 md:pt-10 md:pb-10'>
                    <h1 className='h1 text-4xl md:text-5xl h-auto leading-normal sm:leading-loose  text-gray-800 mb-8'>
                    Forward 
                   <span className='text-primary-200 t pl-2'>
                   together
                    </span>  
                    </h1>
                    <p className='text-lg font-normal text-left sm:w-2/3 mb-3 w-9/12 text-[#3F3A3B]'>
                    We've listed a few industries that we like. If you're not sure if your business fits the bill, we'd love to talk anyway.
                                     </p>

                    <div
                     className='flex mt-2 flex-col justify-center items-center md:items-end  md:pt-10 md:pb-10'
                     data-aos='fade-left'
                     data-aos-duration='2000'
                     >
                    <img
                    src={Cube}
                    loading='lazy'
                    alt='3d Cube'
                    className='h-96 -ml-8 w-96 object-cover'
                    />
                </div>
                </div>

              <section className="py-20 lg:w-1/2 bg-primary-light" id="features">
                <div className=" mx-2 md:mx-auto">
     
                    <div className="grid grid-cols-4 gap-8 mt-10 sm:grid-cols-8 lg:grid-cols-8 sm:px-8 xl:px-0">
                   
                        <div className="relative flex items-end justify-start col-span-4 overflow-hidden">
                        <div className=" text-xs border-l-2 border-primary-200 px-2 md:text-sm font-medium tracking-tight text-left">
                    <h6 className=" w-48 md:w-56">
                    Horizontal software solutions across industries
                        </h6>
                        </div>
                        </div>

                        <div className="relative flex items-end justify-start col-span-4 overflow-hidden">
                        <div className=" text-xs border-l-2 border-primary-200 pl-2 md:text-sm font-medium tracking-tight text-left">
                    <h6 className=" w-48 md:w-56">
                    Vertical software solutions focused in a specific niche
                        </h6>
                        </div>
                        </div>

                        <div className="flex flex-col h-52 items-start border-t-2 border-primary-400 justify-between col-span-4 px-4 py-4 space-y-4 bg-white">
                            <div className="p-3  text-white rounded-full">
                            <img src={position} alt="position" className="w-11 h-11" />
                            </div>
                            <h4 className="text-xl pb-3 font-medium uppercase text-gray-700">
                            Analytics & <br />  business intelligence
                                </h4>
                        </div>

                        <div className="flex flex-col h-52 items-start border-t-2 border-primary-400 justify-between col-span-4 px-4 py-4 space-y-4 bg-white">
                            <div className="p-3 text-white  rounded-full">
                            <img src={money} alt="chart" className="w-11 h-11" />
                            </div>
                            <h4 className="text-xl pb-3 font-medium uppercase text-gray-700">Financial services 
& fintech</h4>
                        </div>

                        <div 
                        className="flex flex-col h-52 items-start border-t-2 border-primary-400 justify-between col-span-4 px-4 py-4 space-y-4 bg-white">
                            <div className="p-2 text-white rounded-full">
                            <img src={chart} alt="chart" className="w-11 h-11" />
                            </div>
                            <h4 className="text-xl pb-3 font-medium uppercase text-gray-700">Payments & sales</h4>
                        </div>

                        

                        <div
className="flex flex-col h-52 items-start border-t-2 border-primary-400 justify-between col-span-4 px-4 py-4 space-y-4 bg-white">
                            <div className="p-3 text-white rounded-full">
                            <img src={flower} alt="flower" className="w-11 h-11" />
                            </div>
                            <h4 className="text-xl pb-3 font-medium uppercase text-gray-700">healthCare</h4>
                        </div>
                        <div 
className="flex flex-col h-52 items-start border-t-2 border-primary-400 justify-between col-span-4 px-4 py-4 space-y-4 bg-white">

                            <div className="p-3 text-white rounded-full">
                            <img src={shield} alt="flower" className="w-11 h-11" />
                            </div>
                            <h4 className="text-xl font-medium uppercase text-gray-700">Cybersecurity</h4>
                        </div>

                        <div
                         className="flex flex-col pb-3 h-52 items-start border-t-2 border-primary-400 justify-between col-span-4 px-4 py-4 space-y-4 bg-white">

                            <div className="p-3 text-white rounded-full">
                            <img src={flower2} alt="flower" className="w-11 h-11" />
                            </div>
                            <h4 className="text-xl pb-3 font-medium uppercase text-gray-700">Education</h4>
                        </div>

                    </div>
                </div>
            </section>
            </div>


            </div>     
        </section>
  )
}
