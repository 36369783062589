import React from 'react'
import Fox from '../assets/img/aboutClients/Fox.svg'
import Google from '../assets/img/aboutClients/Google.svg'
import Walmart from '../assets/img/aboutClients/Walmart.svg'
import pizza from '../assets/img/aboutClients/Pizza Hut.svg'
import Philips from '../assets/img/aboutClients/Philips.svg'
import CNN from '../assets/img/aboutClients/CNN.svg'
import Beanstalk from '../assets/img/aboutClients/Beanstalk.svg'
import Adidad from '../assets/img/aboutClients/Adidad.svg'

const Client = [
    {
        id: 1,
        img: Fox,
        alt: 'Fox'
    },
    {
        id: 2,
        img: Google,
        alt: 'Google'
    },
    {
        id: 3,
        img: Walmart,
        alt: 'Walmart'
    },
    {
        id: 4,
        img: pizza,
        alt: 'Pizza Hut'
    },
    {
        id: 5,
        img: Philips,
        alt: 'Philips'
    },
    {
        id: 6,
        img: CNN,
        alt: 'CNN'
    },
    {
        id: 7,
        img: Beanstalk,
        alt: 'Beanstalk'
    },
    {
        id: 8,
        img: Adidad,
        alt: 'Adidad'
    }


]


export const AboutClient = () => {
  return (
    <section className="py-12 sm:py-16 bg-darker-100 ">
    <div className="max-w-7xl px-10 pt-6 mx-auto sm:text-center">
        <h2 className="font-bold pb-2 text-4xl text-primary-200 sm:text-5xl lg:text-6xl mt-3">
        Our Great Clients
            </h2>
        <p className="mt-4 text-white font-medium text-base sm:text-xl lg:text-2xl">
        Startup includes great form options for your startup projects. Each component is coded for web which makes creating a website quick and easy.
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-10 my-12 sm:my-16">
           {
                Client.map((item) => {
                    return(

            <div key={item.id} className="rounded-lg hover:cursor-pointer py-10 flex flex-col items-center justify-center shadow-lg border border-gray-100">
                          
                            <img src={item.img} alt={item.alt} />
                        </div>
                    )
                })
           }
        </div>
            </div>
</section>
  )
}
