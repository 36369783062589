/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useEffect, useState} from 'react';
import { NavLink } from 'react-router-dom';

// import data
import { nav } from '../data';

const Nav = () => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    // scroll event
    window.addEventListener('scroll', () => {
      window.scrollY > 80 ? setIsActive(true) : setIsActive(false);
    });
  });
  return (
    <nav className='hidden lg:flex'>
      <ul className='flex gap-x-8 text-white'>
        {nav.map((item, idx) => {
          return (
            <li key={idx}>
              <NavLink to={item.href} href='#' 
              // className='hover:text-primary-200 transition'
              className={`
              ${isActive ? 'text-black' : 'text-white'}
              hover:text-primary-200 transition
              `}
              >
                {item.name}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Nav;
