// import Vr from '../assets/img/banner/VR Image.png';
import React from 'react';

let title = 'Products';

let text = 'Some networks believe that by only offering a basic tracking system their affiliates can reach a high level of creativity. We like to set the bar a lot higher by offering you cutting-edge tech solutions together with a team of creative experts.'

const AboutBanner = () => {
  return (
    <section className='bg-aboutBg bg-cover bg-no-repeat h-[790px]'>
      <div className='container h-full'>
        <div className='flex items-center font-primary h-full relative'>
         
          <div className='flex-1 z-10 pl-4 sm:pl-8 md:pl-10 lg:pl-8'>
            <h1
              className='h1 sm:text-7xl font-extrabold text-transparent bg-gradient-to-r bg-clip-text from-[#3D5AA9] to-[#48C5ED]/100 md:text-9xl text-6xl h-auto leading-normal sm:leading-loose mb-8'>
              {title}
            </h1>

            <div
                className='flex justify-start mr-2 font-primary leading-relaxed md:leading-loose mt-4 items-center lg:w-[65%] lg:h-[50%] z-0'
                data-aos='fade-left'
            data-aos-duration="2000"
                >
                <p className='text-base w-96 md:w-full md:max-w-2xl md:text-lg pr-2 font-medium text-left text-ligther-100'>
                  {text}</p>
                </div>                  
          </div>       
        </div>
      </div>
    </section>
  );
};

export default AboutBanner;