/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import scroll from '../assets/img/banner/scroll.png'


const title = ' Solutions that will change your business forever '

const subtitle1 = ' Some networks believe that by only offering a basic tracking system their affiliates can reach a high level of creativity. We like to set the bar a lot higher by offering you cutting-edge tech solutions together with a team of creative experts'
const BusinessServiceArray = [
    {
        title: 'Software',
        titlebottom: 'Development',
        subtitle: 'Build and deploy quality, scalable and secure digital products fast',
        Icon: <svg 
        xmlns="http://www.w3.org/2000/svg"
         fill="none"
       
          viewBox="0 0 24 24" 
          strokeWidth={1.5}
           stroke="currentColor" 
        className="w-16 h-14 group-hover:text-white text-primary-200"
        >
<path strokeLinecap="round" strokeLinejoin="round" d="M3 8.25V18a2.25 2.25 0 002.25 2.25h13.5A2.25 2.25 0 0021 18V8.25m-18 0V6a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 6v2.25m-18 0h18M5.25 6h.008v.008H5.25V6zM7.5 6h.008v.008H7.5V6zm2.25 0h.008v.008H9.75V6z" />
</svg>,
    },
    {
        title: 'Data',
        titlebottom: 'Solution',
        subtitle: 'Leverage data at scale to improve efficiency.',
        Icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" 
        className="w-16 h-14 group-hover:text-white text-primary-200"
        >
        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 3v1.5M4.5 8.25H3m18 0h-1.5M4.5 12H3m18 0h-1.5m-15 3.75H3m18 0h-1.5M8.25 19.5V21M12 3v1.5m0 15V21m3.75-18v1.5m0 15V21m-9-1.5h10.5a2.25 2.25 0 002.25-2.25V6.75a2.25 2.25 0 00-2.25-2.25H6.75A2.25 2.25 0 004.5 6.75v10.5a2.25 2.25 0 002.25 2.25zm.75-12h9v9h-9v-9z" />
      </svg>,
      
    },
    {
        title: 'Cloud',
        titlebottom:'Infrastructure',
        subtitle: 'Some networks believe that by only offering. ',
        Icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" 
        className="w-16 h-14 group-hover:text-white text-primary-200"
        >
        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 15a4.5 4.5 0 004.5 4.5H18a3.75 3.75 0 001.332-7.257 3 3 0 00-3.758-3.848 5.25 5.25 0 00-10.233 2.33A4.502 4.502 0 002.25 15z" />
      </svg>
      
    },
    {
      title: 'Web 3',
      titlebottom:'Solutions',
      subtitle: 'Some networks believe that by only offering.',
      Icon: <svg         
      xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" 
      className="w-16 h-14 group-hover:text-white text-primary-200"
      >
      <path strokeLinecap="round" strokeLinejoin="round" d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418" />
    </svg>
  },
]


export const Product = () => {
  return (
    <div>
  <section className="text-gray-600 body-font">
         {/* scroll down div */}
         <div className='hidden sm:top-0 sm:left-0 sm:right-0 sm:flex justify-center items-center mt-4'>
            <div className='flex flex-col pt-6 items-center w-full mx-auto '>
              <img 
              src={scroll}
              alt=''
              className='h-20 w-auto mb-10 animate-bounce '
              />
          
              </div>
              </div>
  <div className="container px-4 lg:px-5 font-primary py-24 mx-auto">

    <div className="text-center flex flex-col items-center mb-20">
      <h1 className="h3 text-center font-bold h-auto sm:w-1/2 md:w-2/3 leading-normal sm:leading-relaxed  text-gray-700 mb-8
      sm:text-3xl px-1 text-2xl title-font">
        {title}
        </h1>
      <p className="text-lg  font-normal sm:w-3/4 text-gray-500 leading-relaxed xl:w-3/4 lg:w-3/4 mx-auto ">
{subtitle1}
        </p>
    </div>

    <div
      // use grid to make the cards responsive
      className="grid px-0 place-items-center grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
     {/* map through the array */}
        {BusinessServiceArray.map((item, index) => (
      <div
        key={index}
       className="py-4 mt-4 group hover:bg-primary-200  px-4 h-80 w-80 md:w-64 flex flex-col text-center items-center hover:translate-x-1 hover:translate-y-1 hover:scale-105 hover:shadow-2xl transition duration-500 ease-in-out transform">
        
        <div className="w-20 h-20 inline-flex mb-1 flex-shrink-0">
   
{/* <FIcon /> */}
{item.Icon}
        </div>
        <div className="flex-grow">          
          <h2 className="h3 text-2xl font-bold  h-auto  leading-normal sm:leading-relaxed group-hover:text-white text-gray-900 title-font">
          {item.title}
            </h2>
            <h2 className='h3 text-2xl font-bold  h-auto leading-normal group-hover:text-white sm:leading-relaxed text-gray-900 title-font mb-3'>            
          {item.titlebottom}
            </h2>
          <p className="leading-relaxed w-64 md:w-60 px-1 group-hover:text-white  pb-3 text-base">
          {/* {item.subtitle} */}
          </p>
          <a href='#' 
          className="mt-5 inline-flex items-center">
            <svg fill="none" stroke="currentColor" strokeLinecap="round" stroke-linejoin="round" stroke-width="2"
             className="w-4 h-4 group-hover:text-white" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </a>
        </div>
      </div>
        ))}
     {/* \end of map */}
     
    </div>
  </div>
</section>
    </div>
  )
}
