import React from 'react'
import { ServiceBanner } from '../components/ServiceBanner'
import { Product } from '../components/Product';
import { ServiceChoose } from '../components/ServiceChoose';
import { Cube } from '../components/temp';
import { ServiceBoxSection } from '../components/ServiceBox';
import  ServiceForm  from '../components/ServiceForm';



export const Service = () => {
  return (
    <div>
        <ServiceBanner />
        <Product />
        <ServiceChoose />
        <Cube />
        <ServiceBoxSection />
        <ServiceForm />
    </div>
  )
}
