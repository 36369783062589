// import Vr from '../assets/img/banner/VR Image.png';
import React from 'react';

import quotes from '../assets/img/contacts/quotes.svg';

let text = 'Some networks believe that by only offering a basic tracking system their affiliates can reach a high level of creativity. We like to set the bar a lot higher by offering you cutting-edge tech solutions together with a team of creative experts.'

const ContactBanner = () => {
  return (
    <section className='bg-aboutBg bg-cover bg-no-repeat h-[790px]'>
      <div className='container h-full'>
        <div className='flex items-center h-full relative'>
        
  <div className="p-8 md:p-12 lg:py-12">
    <div className="mx-auto text-left">
        <img src={quotes} alt="quotes" className='w-16 h-16 mb-4' />
      <h2 className="text-3xl leading-relaxed md:leading-loose font-bold text-[#fafafa] mb-5 md:text-4xl lg:text-5xl"
      data-aos="fade-down-right"
      >
      We make amazing products <br />
       and <span className='text-primary-200'> we’ll make yours amazing, too </span>
      </h2>
<p
     data-aos="fade-down-right"
       className="text-gray-300 w-96 sm:w-3/5 md:w-4/5 leading-relaxed md:leading-loose block font-medium text-sm md:text-base font-primary pr-2 md:mt-4 md:block">
     {text}
      </p>
    
    </div>
  </div>

</div>
</div>
</section>

  );
};

export default ContactBanner;