import React from 'react'
import './temp.css'
import cubet from '../assets/img/service/Cubet.png';


export const Cube  = () => {
  return (
    <>
    <div 
    className="container text- mx-auto flex flex-col md:flex-row pt-12 items-center justify-center h-auto ">

    <div class="points md:w-1/2 h-auto m-0 p-0 box-border font-primary">
        <div class="points-item">
      <span>30 min</span>
      <p> Intro
        <br/>
Meetinge
      </p>
    </div>
    <div class="points-item">
      <span>48 hours</span>
      <p>Sign NDA
Preliminary analysis
Submit initial offer.        </p>
    </div>
    <div class="points-item">
      <span>2 weeks</span>
      <p>
      Letter of intent
        </p>
    </div>
    <div class="points-item">
      <span>60 days</span>
      <p>
      Due dilligence
        </p>
    </div>
    <div class="points-item  before:left-0">
      <span>60 days</span>
      <p>
      Due dilligence
        </p>
        </div>
  </div>
  {/* create a section with a titile and a paragraph with a button and an image on the bottom right */}

  <div className="md:w-1/2 mt-20 font-primary">
    <div className='pl-4 py-4'>

    <h1 className="text-3xl font-bold  text-gray-800">Exit
    <span className=" pl-3 bg-clip-text tracking-tight text-transparent bg-gradient-to-r from-blue-300 to-primary-200"> 
  
     fast.
     </span>
    </h1>
    <p className="text-gray-600 text-sm md:text-base pt-3 max-w-xs">
    Our process emphasizes fast, lucrative exits for software founders
      </p>
    <button className="btn bg-primary-200 text-white mt-5 w-48 rounded-full hover:bg-primary-200 hover:scale-105 btn-sm lg:btn-lg text-sm">
      Get Started
      </button>
      <div
      className='md:-mr-20'
      data-aos='fade-left'

      >

    <img src={cubet} alt="cubet" 
    loading='lazy'
    className="" />
      </div>

    </div>
    </div>
    


  </div>
    </>
    
  )
}
