import React from 'react';

import Vr from '../assets/img/banner/Mob.gif';
import VrM from '../assets/img/banner/gif.gif';


import clients from '../assets/img/banner/clients.svg';
import scroll from '../assets/img/banner/scroll.png'


// import data
import { banner } from '../data';

const Banner = () => {
  // destructure banner data
  const { title, textBtn } = banner;
  return (
    <section className='bg-banner bg-cover bg-no-repeat h-[790px]'>
      <div className='mx-auto px-4 lg:px-5 h-full'>
        <div className='flex flex-col md:flex-row items-center h-full relative'>
          <div className='flex flex-col pt-32 md:pt-2 md:w-7/12 z-10 pl-4 lg:pl-8'>
            <h1
              className='h1 text-2xl md:text-4xl sm:text-3xl h-auto leading-normal sm:leading-loose  text-gray-700 mb-8'
              data-aos='fade-down'
              data-aos-duration='1000'
            >
              {title}
            </h1>
            <button
              className='btn bg-primary-200 w-48 md:w-80 text-white hover:text-primary-200 rounded-full btn-sm lg:btn-lg btn-secondary'
              data-aos='fade-down'
              data-aos-delay='700'
            >
              {textBtn}
            </button>

            {/* a div containing an image  */}
            <div
              className='flex justify-start mt-4 items-center lg:w-[50%] lg:h-[50%] z-0'
              data-aos='fade-left'
              data-aos-delay='300'
            >
              <img src={clients} alt='' 
              className=' h-auto w-60 object-cover' />
              </div>             
          </div>
          
<div className='md:w-5/12'>

          <img
          src={Vr}
          alt='a man with a VR headset'
          loading='lazy'
            className='mt-4 w-[500px] sm:hidden md:w-[700px] h-auto object-cover'
            data-aos='zoom-in-rigth'
            data-aos-delay='300'
            />
               <img
          src={VrM}
          loading='lazy'
          alt='a man with a VR headset'
            className='mt-4 w-[500px] hidden sm:block md:w-[700px] h-auto object-cover'
            data-aos='zoom-in-rigth'
            data-aos-delay='300'
            />
</div>

            {/* scroll down div */}
            <div className='md:hidden bottom-0 left-0 right-0 sm:flex justify-center items-center mt-4'>
            <div className='flex flex-col pt-6 items-center w-full mx-auto '>
              <img 
              src={scroll}
              alt=''
              className='h-20 w-auto mb-10 animate-bounce '
              />
          
              </div>
              </div>

        </div>
         
      </div>
    </section>
  );
};

export default Banner;