import React from 'react'

import google  from '../assets/img/clients/google.svg';
import microsoft from '../assets/img/clients/microsoft.svg';
import netflix from '../assets/img/clients/netflix.svg';
import amazon from '../assets/img/clients/amazon.svg';
import spotify from '../assets/img/clients/Shape.svg';
import paypal from '../assets/img/clients/Paypal.svg';



// const responsive = {
//     desktop: {
//       breakpoint: { max: 3000, min: 1024 },
//       items: 6,
//       slidesToSlide: 1 // optional, default to 1.
//     },
//     tablet: {
//       breakpoint: { max: 1024, min: 464 },
//       items: 4,
//       slidesToSlide: 1 // optional, default to 1.
//     },
//     mobile: {
//       breakpoint: { max: 464, min: 0 },
//       items: 3,
//       slidesToSlide: 1 // optional, default to 1.
//     }
//   };

export const Clients = () => {
  return (
    <section className='lg:flex bg-primary-400'>
    <div className='lg:flex pb-24 md:pb-10 px-3'>
 <div className=" px-4 lg:w-1/3  pt-24 pb-12 sm:pb-16 ">
    <div className=" flex flex-col items-start pl-2 mb-10">
      <h1 className="h3 text-white title font-bold text-start">
       Our Clients
        </h1>
      <p className="text-lg  font-normal sm:w-3/4 text-white leading-relaxed xl:w-3/4 lg:w-3/4 ">
      Our customers say switching to 
       <span className='font-bold text-black hover:text-white hover:cursor-pointer hover:shadow-2xl hover:shadow-gray-800 '> Velion </span>   is a breeze. Our success is measured by how
our clients feel about their experience with us.
        </p>
    </div>
    </div>

 <div className="grid lg:w-2/3 grid-cols-2 lg:grid-cols-3 gap-5 mx-2 pb-4 sm:mt-10 mb-12">
 
 <div
  className="rounded-lg bg-white hover:cursor-pointer hover:shadow-2xl hover:shadow-gray-800 py-10 flex flex-col items-center justify-center shadow-xl shadow-zinc-500 ">
                <img 
                    src={amazon}
                    className="w-40"
                    loading="lazy" alt="client logo" width="" height="" />
                
                </div>
                <div
  className="rounded-lg bg-white hover:cursor-pointer hover:shadow-2xl hover:shadow-gray-800 py-10 flex flex-col items-center justify-center shadow-xl shadow-zinc-500 ">
                <img 
                    src={spotify}
                    className="w-40"
                    loading="lazy" alt="client logo" width="" height="" />
                
                </div>
                <div
  className="rounded-lg bg-white hover:cursor-pointer hover:shadow-2xl hover:shadow-gray-800 py-10 flex flex-col items-center justify-center shadow-xl shadow-zinc-500 ">
             
                <img 
                    src={paypal}
                    className="w-40"
                    loading="lazy" alt="client logo" width="" height="" />
                
                </div>
                <div
  className="rounded-lg bg-white hover:cursor-pointer hover:shadow-2xl hover:shadow-gray-800 py-10 flex flex-col items-center justify-center shadow-xl shadow-zinc-500 ">
             
                <img 
                    src={netflix}
                    className="w-40"
                    loading="lazy" alt="client logo" width="" height="" />
                
                </div>
                <div  className="rounded-lg bg-white hover:cursor-pointer hover:shadow-2xl hover:shadow-gray-800 py-10 flex flex-col items-center justify-center shadow-xl shadow-zinc-500 ">
               
                <img 
                    src={google}
                    className="w-40"
                     loading="lazy" alt="client logo" width="" height="" />
                
                </div>
                <div         className="rounded-lg bg-white hover:cursor-pointer hover:shadow-2xl hover:shadow-gray-800 py-10 flex flex-col items-center justify-center shadow-xl shadow-zinc-500 ">
              
                <img 
                    src={microsoft}
                    className="w-40"
                    loading="lazy" alt="client logo" width="" height="" />
                
                </div>
             
                </div>
          

    </div>
    </section>

  )
}
