import React from 'react'
import Banner from '../components/Banner';
import { Product } from '../components/Product';
// import { ProductList } from '../components/ProductList';
import { Clients } from '../components/Clients';
import { Testimonal } from '../components/Testimonal';
import { ProductListNew } from '../components/ProductListNew';

export const Home = () => {
  return (
    <div
    className='w-full overflow-hidden' 
    >
         <Banner />
      <Product />
      <ProductListNew />
      {/* <ProductList /> */}
      <Clients />
      <Testimonal />
      </div>
  )
}
