import React from 'react'

import man from '../assets/img/testimonal/man.png';
import manSecond from '../assets/img/testimonal/man2.png';



import quotes from '../assets/img/testimonal/quotes.svg';

export const Testimonal = () => {
  return (
    <section className="bg-white">
    <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
  
      <div
        className="mt-16 grid grid-cols-1 gap-12 sm:grid-cols-2 sm:gap-16 lg:grid-cols-3"
      >
        {/* andrey  */}
       <div className='rounded-md shadow-md'>

<img
  alt="Woman"
  src="https://images.unsplash.com/photo-1599566219227-2efe0c9b7f5f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80"
  className="mx-auto -mt-10 h-24 w-24 rounded-full object-cover shadow-xl"
/>

<blockquote
  className="-mt-6  flex flex-col justify-between items-center rounded-lg p-10 text-center shadow-xl"
>
  <div className="text-2xl font-medium text-gray-900">
  <p className="text-lg md:text-xl font-bold text-[#3D5AA9]">
  Allan Kris
      </p>
  <p className="mt-1 text-xs md:text-sm sm:font-medium font-normal text-gray-500">
  Founder of switchboard
    
  </p>
  <p className="mt-1 text-xs font-normal text-gray-500">
  -SWITCHBOARD-
  </p>
  </div>

  {/* quotes icon svg position centered  */}
  <div className="flex items-center pt-4 justify-center">
   <img src={quotes} alt="" className="h-6 w-6" />
  </div>

<p className="text-xs mt-5 pt-1 flex items-center justify-center  leading-loose  w-60 h-auto md:text-sm font-normal text-gray-500">
  I’ve been working with Velion for nearly 2 years now.
   Friendly team, fast development, quality programmers. Highly recommend them.
  </p>

 
</blockquote>
</div>
  
       
        <div className='rounded-md shadow-md'>

          <img
            alt="Woman"
            src={man}
            className="mx-auto -mt-10 h-24 w-24 rounded-full object-cover shadow-xl"
          />
  
          <blockquote
            className="-mt-6  flex flex-col justify-between items-center rounded-lg p-10 text-center shadow-xl"
          >
            <div className="text-2xl font-medium text-gray-900">
            <p className="text-lg md:text-xl font-bold text-[#3D5AA9]">
            Allan Kris
                </p>
            <p className="mt-1 text-xs md:text-sm sm:font-medium font-normal text-gray-500">
            Founder of switchboard
              
            </p>
            <p className="mt-1 text-xs font-normal text-gray-500">
            -SWITCHBOARD-
            </p>
            </div>

            {/* quotes icon svg position centered  */}
            <div className="flex items-center pt-4 justify-center">
             <img src={quotes} alt="" className="h-6 w-6" />
            </div>

<p className="text-xs mt-5 pt-1 flex items-center justify-center  leading-loose  w-60 h-auto md:text-sm font-normal text-gray-500">
            I’ve been working with Velion for nearly 2 years now.
             Friendly team, fast development, quality programmers. Highly recommend them.
            </p>
  
           
          </blockquote>
        </div>
  
        <div className='rounded-md shadow-md'>

          <img
            alt="Woman"
            src={manSecond}
            className="mx-auto -mt-10 h-24 w-24 rounded-full object-cover shadow-xl"
          />
  
          <blockquote
            className="-mt-6 flex flex-col justify-between items-center rounded-lg p-10 text-center shadow-xl"
          >
            <div className="text-2xl font-medium text-gray-900">
            <p className="text-lg md:text-xl font-bold text-[#3D5AA9]">
            Allan Kris
                </p>
            <p className="mt-1 text-xs md:text-sm sm:font-medium font-normal text-gray-500">
            Founder of switchboard
              
            </p>
            <p className="mt-1 text-xs font-normal text-gray-500">
            -SWITCHBOARD-
            </p>
            </div>

            {/* quotes icon svg position centered  */}
            <div className="flex items-center pt-4 justify-center">
             <img src={quotes} alt="" className="h-6 w-6" />
            </div>

<p className="text-xs mt-5 pt-1  leading-loose w-60 h-auto md:text-sm font-normal text-gray-500">
            I’ve been working with Velion for nearly 2 years now.
             Friendly team, fast development, quality programmers. Highly recommend them.
            </p>
  
           
          </blockquote>
        </div>
  
      </div>
    </div>
  </section>
  
  )
}
