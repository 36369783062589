import React from 'react'
import { Link } from 'react-router-dom';

import Browser from '../assets/img/product/Browser.png';
import Browser1 from '../assets/img/product/Mask.png';

const productSection = [
  {
    title: 'Product 1',
    description: 'A showcase for your awesome iPhone designs.',
    image: Browser,
    alt: 'Product 1',
  },
  {
    title: 'Product 2',
    description: 'You have the design, you have the code.',
    image: Browser1,
    alt: 'Product 2',
  },
  {
    title: 'Product 3',
    description: 'Startup Framework. Suit up your startup.',
    image: Browser,
    alt: 'Product 3',
  },
  {
    title: 'Product 4',
    description: 'Best Apple iWatch apps.',
    image: Browser1,
    alt: 'Product 4',
  },
  {
    title: 'Product 5',
    description: 'Startup Framework. Suit up your startup.',
    image: Browser,
    alt: 'Product 5',
  },
  {
    title: 'Product 6',
    description: 'Best Apple iWatch apps.',
    image: Browser1,
    alt: 'Product 6',
  },
]
export const ProductBottom = () => {
  return (
    // create a section with a grid of two columns and 3 rows positioned in the center space of the grid should be 8px
  <div className='flex justify-center pb-16 items-center bg-[#F3F3F3]  px-2 mx-2 w-full'>
  <section className="grid bg-[#F3F3F3] sm:grid-cols-2 sm:grid-rows-3 my-4 py-2 sm:max-w-2xl gap-4 place-content-center place-items-center  "> 
      {/* map through the productSection array and display the data */}
      {productSection.map((product) => (
        <div className="flex flex-col items-center w-80 h-auto bg-white rounded-sm px-4">
           <h3 className=" text-primary-200 text-base md:text-lg lg:text-xl my-6 pt-2   font-semibold">{product.title}</h3>
          <p className=" text-xs md:text-base pt-4 font-sans text-[#151439]/40 text-center mb-6">
{product.description}</p>
<Link to="/products/1" className="">
          <img

            src={product.image}
            alt={product.alt}
            className="w-56 h-auto  object-cover"
          />

</Link>
         
        </div>
      ))}
    </section>
    </div>

    
  )
}
