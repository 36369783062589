import React, {useEffect} from 'react';
import { Route, Routes  } from 'react-router-dom';

// import aos
import AOS from 'aos';
import 'aos/dist/aos.css';

// import components
import { Home } from './Pages/Home';
import { AboutUs } from './Pages/AboutUs';
import Layout from './layout/Layout';
import { Product } from './Pages/Product';
import { ContactUs } from './Pages/ContactUs';
import { Service } from './Pages/Service';
import { Productids } from './Pages/Productids';
import { FourOhFour } from './Pages/FourOhFour';

const App = () => {
  // aos initialization
  useEffect(() => {
    AOS.init({
      duration: 750,
      once: false,
    });
  }, []);
  return (
    <div className='w-screen overflow-hidden mx-auto bg-page relative'> 
    <Layout>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<AboutUs/>} />
        <Route path='/products' element={<Product />} />
        <Route path='/contact' element={<ContactUs /> } /> 
        <Route path='/service' element={<Service />} />
        <Route path='products/:id' element={<Productids />} />
        <Route path='*' element={<FourOhFour />} />
      </Routes>
    </Layout>

   
    </div>
  );
};

export default App;
