import React from 'react'

export const Chooseus = () => {
  return (
    <section 
    className='container w-full '>
  
        <div className= " ml-3 w-full flex flex-col py-12 items-start justify-start h-auto ">
<h3 className="text-4xl font-primary font-semibold md:font-bold leading-relaxed text-primary-200 text-center pt-20">
            When you contact us

        </h3>
        <p className="text-lg font-primary font-normal leading-relaxed text-black text-left pt-4">
            we offer 2 consult packages:
        </p>
        </div>

    </section>
  )
}
