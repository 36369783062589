import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function CheckboxLabels() {
  return (
    <FormGroup>
      <FormControlLabel control={<Checkbox defaultChecked />} 
      label="Employee Management" />
      <FormControlLabel  control={<Checkbox />} 
      className="text-[#0A1F44]"
      label="Announcement Management" />
       <FormControlLabel  control={<Checkbox />} 
      className="text-[#0A1F44]"
      label="Acount Management" />
       <FormControlLabel  control={<Checkbox />} 
      className="text-[#0A1F44]"
      label="Project Management" />
       <FormControlLabel  control={<Checkbox />} 
      className="text-[#0A1F44]"
      label="Announcement Management" />
    </FormGroup>
    
  );
}
