import React from 'react'
import  ProductBanner from '../components/ProductBanner';
import { ProductBottom } from '../components/ProductBottom';
import { ProductDetails } from '../components/ProductDetails';

export const Product = () => {
  return (
    <div>
        <ProductBanner />
        <ProductDetails />
        <ProductBottom />
    </div>
  )
}
