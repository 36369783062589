import React from 'react'

import scroll from '../assets/img/banner/scroll.png';
import quotes from '../assets/img/testimonal/about-quotes.svg';

export const AboutVision = () => {
  return (
    <section className="bg-white flex flex-col justify-center items-center">
        {/* scroll down div */}
          <div className='hidden sm:top-0 sm:left-0 sm:right-0 sm:flex justify-center items-center mt-4'>
            <div className='flex flex-col items-center w-full mx-auto '>
              <img 
              src={scroll}
              alt=''
              className='h-20 w-full mb-10 animate-bounce '
              />
          
              </div>
              </div>

              {/* center text about the company vision  */}
                <div className='flex flex-col mx-auto mt-40 items-center'>
                    {/* import quotes svg here */}
                    <img src={quotes} alt='' className='h-20 w-20' />
                    <h1 className='h-80 w-96  font-bold text-center text-4xl leading-normal mb-4'
                    data-aos='fade-down'
                    data-aos-delay='500'
                     >
                        Our vision <span className=' font-normal text-primary-200'>
                             is to become the world’s leading company of realizing </span>ideas.
                    </h1>
                </div>

    </section>
  )
}
