import React from 'react'
import vFull from '../assets/img/service/v-full.svg';
import vHalf from '../assets/img/service/v-full.svg';


export const ServiceBanner = () => {
  let text = 'Some networks believe that by only offering a basic tracking system their affiliates can reach a high level of creativity. We like to set the bar a lot higher by offering you cutting-edge tech solutions together with a team of creative experts.'

  return (
    <>
      {/* create a div with a  'bacground color #231F20' and  two image postion absolute in the top right and bottom left 
     */}

      <section className='bg-[#231F20] h-[790px]'>
        <div className='container h-full'>
          <div className='absolute top-0 right-0'>
            <img src={vFull} alt="" 
            data-aos="flip-right"
            data-aos-duration="2000"

            className='w-56 md:w-full' />
          </div>
          <div className='flex items-center h-full relative'>
            <div className="absolute bottom-0 left-0">

              <img src={vHalf} alt="velion"
             data-aos="flip-left"
             data-aos-duration="1000"
               className='w-56 md:w-96 md:-ml-36 -ml-20' />
            </div>



            <div className="p-8 md:p-12 lg:py-12">
              <div className="mx-auto text-left">
                <h2 className="text-3xl leading-relaxed md:leading-loose font-bold text-[#fafafa] mb-5 md:text-4xl lg:text-5xl"
                  data-aos="fade-down-right"
                >
                 <span className='text-primary-200'>
                 Software Development
                 </span>
                 <br />
                 Services
                </h2>
                <p
                  data-aos="fade-down-right"
                  data-aos-duration="2000"
                  className="text-gray-300 w-96 pr-2 sm:w-3/5 md:w-4/5 leading-relaxed md:leading-loose block font-medium text-sm md:text-base  font-primary md:mt-4 md:block">
                  {text}
                </p>

              </div>
            </div>

          </div>
        </div>
      </section>


    </>
  )
}
