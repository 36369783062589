import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";


const Layout = ({children}) => {
    return (
        <div className="w-screen overflow-hidden mx-auto bg-page relative">
            <Header />
            {children}
            <Footer />
        </div>
    );
};

export default Layout;
