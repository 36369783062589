import React from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, A11y } from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';

import Browser from '../assets/img/product/Browser@2x.png';


const title="Our Products"

const description='Since the day of our birth, we are committed to delivering high-quality services to our clients, helping them grow their business at the world class level.'

export const ProductListNew = () => {
  return (
    <section>
 <div className="container px-5 py-12 mx-auto">
    <div className="text-center flex flex-col items-center mb-10">
      <h1 className="h3 title ">
        {title}
        </h1>
      <p className="text-lg font-normal sm:w-3/4 text-gray-500 leading-relaxed xl:w-3/4 lg:w-3/4 mx-auto ">
{description}
        </p>
    </div>

    {/* see all button center */}
    <div className="flex justify-center">
    <button className="btn btn-sm bg-primary-200 rounded-full w-40 text-white">
    See All
    </button>
    </div>

    </div>
    <div className="flex mx-auto p-2">
    <Swiper
      modules={[Navigation, A11y]}
      spaceBetween={15}
        // slidesPerView={2}
        breakpoints={{
            // when window width is >= 640px
            640: {
              slidesPerView: 1,
            },
            // when window width is >= 768px
            768: {
              slidesPerView: 3,
            },
            // when window width is >= 1024px
            1024: {
              slidesPerView: 3,
            },
          }}
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
      navigation
      className="mySwiper mx-auto"
    >
      <SwiperSlide>
      <div className="flex flex-col my-10 justify-center h-96 w-96">
            {/* image  */}
            <div className="flex justify-center">
            <img src={Browser} alt="browser" className=""/>
            </div>
            <div className="flex flex-col mt-2 items-start text-start px-4">
            <h1 className="text-center font-medium sm:font-semibold h-auto leading-normal sm:leading-relaxed text-gray-700 mb-2 sm:text-xl text-lg title-font">
                Product 1
                </h1>
                {/* description */}
                <p className="text-base font-normal  text-gray-500 leading-relaxed">
                Please add your content here. Keep it short and simple. And smile :),
                </p>
                </div>
                {/* button */}
                <div className="flex flex-col mt-10 items-start text-start px-4">
                <button className=" text-primary-200 hover:text-white border-2 border-primary-200 rounded-sm py-2 px-6 focus:outline-none hover:bg-primary-200 text-base transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105">
                    
                Explore
                </button>
                </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
          <div className="flex flex-col my-10 justify-center h-96 w-96">
            {/* image  */}
            <div className="flex justify-center">
            <img src={Browser} alt="browser" className=""/>
            </div>
            <div className="flex flex-col mt-2 items-start text-start px-4">
            <h1 className="text-center font-medium sm:font-semibold h-auto leading-normal sm:leading-relaxed text-gray-700 mb-2 sm:text-xl text-lg title-font">
                Product 2
                </h1>
                {/* description */}
                <p className="text-base font-normal  text-gray-500 leading-relaxed">
                Please add your content here. Keep it short and simple. And smile :),
                </p>
                </div>
                {/* button */}
                <div className="flex flex-col mt-10 items-end text-start px-4">
                <button className=" text-primary-200 hover:text-white border-2 border-primary-200 rounded-sm py-2 px-6 focus:outline-none hover:bg-primary-200 text-base transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105">
                Explore
                </button>
                </div>
        </div></SwiperSlide>

      <SwiperSlide> 
         <div className="flex flex-col my-10  justify-center h-96 w-96">
            {/* image  */}
            <div className="flex justify-center">
            <img src={Browser} alt="browser" className=""/>
            </div>
            <div className="flex flex-col mt-2 items-start text-start px-4">
            <h1 className="text-center font-medium sm:font-semibold h-auto leading-normal sm:leading-relaxed text-gray-700 mb-2 sm:text-xl text-lg title-font">
                Product 3
                </h1>
                {/* description */}
                <p className="text-base font-normal  text-gray-500 leading-relaxed">
                Please add your content here. Keep it short and simple. And smile :),
                </p>
                </div>
                {/* button */}
                <div className="flex flex-col mt-10 items-end text-start px-4">
                <button className=" text-primary-200 hover:text-white border-2 border-primary-200 rounded-sm py-2 px-6 focus:outline-none hover:bg-primary-200 text-base transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105">
                    
                Explore
                </button>
                </div>
        </div></SwiperSlide>
        <SwiperSlide>  <div className="flex flex-col my-10 justify-center h-96 w-96">
            {/* image  */}
            <div className="flex justify-center">
            <img src={Browser} alt="browser" className=""/>
            </div>
            <div className="flex flex-col mt-2 items-start text-start px-4">
            <h1 className="text-center font-medium sm:font-semibold h-auto leading-normal sm:leading-relaxed text-gray-700 mb-2 sm:text-xl text-lg title-font">
                Product 4
                </h1>
                {/* description */}
                <p className="text-base font-normal  text-gray-500 leading-relaxed">
                Please add your content here. Keep it short and simple. And smile :),
                </p>
                </div>
                {/* button */}
                <div className="flex flex-col mt-10 items-end text-start px-4">
                <button className=" text-primary-200 hover:text-white border-2 border-primary-200 rounded-sm py-2 px-6 focus:outline-none hover:bg-primary-200 text-base transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105">
                Explore
                </button>
                </div>
        </div>
        </SwiperSlide>


      <SwiperSlide>  <div className="flex flex-col my-10 justify-center h-96 w-96">
            {/* image  */}
            <div className="flex justify-center">
            <img src={Browser} alt="browser" className=""/>
            </div>
            <div className="flex flex-col mt-2 items-start text-start px-4">
            <h1 className="text-center font-medium sm:font-semibold h-auto leading-normal sm:leading-relaxed text-gray-700 mb-2 sm:text-xl text-lg title-font">
                Product 5
                </h1>
                {/* description */}
                <p className="text-base font-normal  text-gray-500 leading-relaxed">
                Please add your content here. Keep it short and simple. And smile :),
                </p>
                </div>
                {/* button */}
                <div className="flex flex-col mt-10 items-end text-start px-4">
                <button className=" text-primary-200 hover:text-white border-2 border-primary-200 rounded-sm py-2 px-6 focus:outline-none hover:bg-primary-200 text-base transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105">
                    
                Explore
                </button>
                </div>
        </div>
        </SwiperSlide>
    
    </Swiper>
    </div>
     
    </section>
  )
}
